export const data = {
  questions: {
    message: 'Остались вопросы?',
    actions: {
      first: {
        title: 'Получить консультацию',
      },
      second: {
        title: 'Посмотреть Программы',
        link: '/#program',
      },
    },
  },
  contacts: {
    title: 'Контакты',
    content:
      'Если вы хотите записаться на курс, получить дополнительную информацию, познакомиться с нами или посетить наш буткемп — звоните/пишите.',
    links: [
      {
        value: 'tel:+74999386824',
        title: '+7 499 938-68-24',
        type: 'tel',
      },
      {
        value: 'https://t.me/elbrus_bootcamp',
        title: 'Telegram',
        type: 'telegram',
      },
      {
        value:
          'https://api.whatsapp.com/send/?phone=74957860581&text&type=phone_number&app_absent=0',
        title: 'Whatsapp',
        type: 'whatsapp',
      },
      {
        value: 'mailto:info@elbrusboot.camp',
        title: 'info@elbrusboot.camp',
        type: 'email',
      },
    ],
    campuses: [
      {
        title: 'Кампус в Москве',
        location: 'ул.Орджоникидзе, д. 11 стр. 10 (м. Ленинский проспект)',
        img: 'msk',
        link: 'https://yandex.ru/maps/-/CDC-rOLX',
        map: 'https://yandex.ru/map-widget/v1/?ll=37.596967%2C55.707636&oid=101761078308&ol=biz&sll=37.596967%2C55.707636&sspn=0.008572%2C0.002938&z=16',
      },
      {
        title: 'Кампус в Санкт-Петербурге',
        location: `Лиговский проспект 140, БЦ Эко−Стандарт, 7 этаж (м. Обводный канал)`,
        img: 'spb',
        link: 'https://yandex.ru/maps/-/CDC-rD6E',
        map: 'https://yandex.ru/map-widget/v1/?ll=30.351116%2C59.913850&oid=64640061144&ol=biz&sll=30.351116%2C59.913850&sspn=0.004256%2C0.001298&z=16',
      },
    ],
  },
  seo: {
    title:
      'Контакты Эльбрус Буткемп – запись на курсы и дополнительная информация ',
    description:
      'Здесь собраны все необходимые контакты, для того, чтоб вы могли просто и быстро узнать всю необходимую информацию о нашем буткемпе и записаться на курсы программирования. Звоните сейчас и не упустите возможность работать в IT-сфере!',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Здесь собраны все необходимые контакты, для того, чтоб вы могли просто и быстро узнать всю необходимую информацию о нашем буткемпе и записаться на курсы программирования. Звоните сейчас и не упустите возможность работать в IT-сфере!',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content:
          'Контакты Эльбрус Буткемп – запись на курсы и дополнительная информация ',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Здесь собраны все необходимые контакты, для того, чтоб вы могли просто и быстро узнать всю необходимую информацию о нашем буткемпе и записаться на курсы программирования. Звоните сейчас и не упустите возможность работать в IT-сфере!',
      },
      {
        property: 'og:image',
        name: null,
        content:
          'https://elbrusboot.camp/static/40d466f0af3679123d6b4f85a12d83d3/74209/slide-2.jpg',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: '@elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content:
          'https://elbrusboot.camp/static/40d466f0af3679123d6b4f85a12d83d3/74209/slide-2.jpg',
      },
    ],
  },
};
