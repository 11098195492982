import React from 'react';
import { Grid, Typography, Box, CardMedia } from '@mui/material';
import { Link } from 'gatsby-theme-material-ui';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useCampusesQuery } from '../../app/queries/campuses.query';
import Section from '../../shared/Section/Section';
import styles from './styles';

function Contacts({ title, content, campuses, links = [], titleH1 = false }) {
  const { campusesData } = useCampusesQuery();

  return (
    <Section title={title} titleH1={titleH1}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography
            variant="desktopP1"
            sx={{
              marginBottom: {
                xs: '24px',
                md: '40px',
              },
              color: 'kit.text.secondary',
            }}
          >
            {content}
          </Typography>
          {links.map((item) => (
            <Box key={item.value} sx={styles.link}>
              <Link
                target="_blank"
                to={item.value}
                variant={item.type === 'tel' ? 'kitSecondary' : 'kitPrimary'}
              >
                {item.title}
              </Link>
            </Box>
          ))}
        </Grid>
      </Grid>
      <Grid container sx={styles.campuses}>
        {campuses.map((item) => (
          <Grid key={item.title} item xs={12} md={5} sx={styles.campus}>
            <Box sx={styles.image}>
              {!!campusesData && (
                <GatsbyImage
                  alt={`campus_${item.img}`}
                  image={getImage(campusesData[`campuses/${item.img}`])}
                />
              )}
            </Box>
            <Typography variant="desktopH3" sx={styles.campusTitle}>
              {item.title}
            </Typography>
            <Typography variant="desktopP1" sx={styles.location}>
              {item.location}
            </Typography>
            <Link
              variant="kitPrimary"
              href={item.link}
              target="_blank"
              sx={styles.mapLink}
            >
              Посмотреть на карте
            </Link>
            <CardMedia
              src={item.map}
              component="iframe"
              frameBorder="0"
              title="map"
              sx={styles.map}
            />
          </Grid>
        ))}
      </Grid>
    </Section>
  );
}

export default Contacts;
