const styles = {
  link: {
    marginBottom: {
      xs: '12px',
      md: '16px',
    },
    a: {
      fontFamily: 'RF Rostin',
      fontSize: {
        xs: '22px',
        md: '34px',
      },
      lineHeight: {
        xs: '32px',
        md: '42px',
      },
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  campuses: {
    paddingTop: {
      xs: '40px',
      md: '80px',
    },
  },
  campus: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: {
      xs: 0,
      md: '72px',
    },
    marginBottom: {
      xs: '48px',
      md: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  image: {
    width: '108px',
    height: '108px',
    borderRadius: '50%',
    marginBottom: '12px',
    overflow: 'hidden',
  },
  campusTitle: {
    color: 'kit.text.main',
    marginBottom: {
      xs: '8px',
      md: '24px',
    },
  },
  location: {
    maxWidth: '356px',
    marginBottom: {
      xs: '24px',
      md: '32px',
    },
    color: 'kit.text.secondary',
  },
  mapLink: {
    mt: 'auto',
    display: 'inline-block',
    fontSize: {
      xs: '14px',
      md: '16px',
    },
    marginBottom: {
      xs: '16px',
      md: '24px',
    },
  },
  map: {
    borderRadius: '20px',
    overflow: 'hidden',
    width: '100%',
    maxWidth: '536px',
    height: {
      xs: '180px',
      md: '232px',
    },
  },
};

export default styles;
