import React, { useEffect, useState } from 'react';
import { sendYandexMetrikaEvent } from '../helpers/yandexMetrika';
import Layout from '../shared/Layout/Layout';
import Contacts from '../widgets/Contacts/Contacts';
import { data } from '../pages-fsd/ContactsPage/model/contacts.data';
import HeadMaker from '../HeadMaker';

export function Head(props) {
  return <HeadMaker {...props} seo={data.seo} />;
}

const mainData = {
  leadPage: 'Контакты',
};

const leadData = {
  question: {
    ...mainData,
    eventName: 'Оставьте ваши контакты для связи',
    leadAction: 'Получить консультацию',
    leadBlock: 'Остались вопросы',
  },
};

function ContactsPage() {
  useEffect(() => {
    // ToDo set correct event
    sendYandexMetrikaEvent('mainProgramView');
  }, []);

  return (
    <Layout leadPage="Контакты">
      <Contacts {...data.contacts} titleH1 />
    </Layout>
  );
}

export default ContactsPage;
